


























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Stories',
  props: {
    cause: {
      type: Object,
      required: true
    }
  },
  setup() {
    const getStoriesPhotos = (filename: string) => {
      return require('../assets/images/stories/' + filename)
    }

    return {
      // methods
      getStoriesPhotos
    }
  }
})
