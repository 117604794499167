


























import { defineComponent } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useRouter } from 'vue2-helpers/vue-router'

export default defineComponent({
  name: 'DialogFooter',
  props: {
    cause: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { useMutations: useImpactMutations } = createNamespacedHelpers('impact')
    const { setCause, resetAllValuesButCause } = useImpactMutations(['setCause', 'resetAllValuesButCause'])

    const router = useRouter()

    const moveOnSetParameters = () => {
      setCause(props.cause.title)
      resetAllValuesButCause()
      router.push('impact')
    }

    // todo: what is this (again)
    // setDataLayer() {
    //   dataLayer.push({
    //     event: 'exploreMyImpact',
    //     ecommerce: {
    //       checkout: {
    //         actionField: { step: 3 }
    //       }
    //     }
    //   })
    // }

    return {
      moveOnSetParameters
    }
  }
})
