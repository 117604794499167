
























import { defineComponent } from '@vue/composition-api'
import { htmlfyJsonString } from '../helpers'

export default defineComponent({
  name: 'CauseDialogSection',
  props: {
    cause: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      // methods
      htmlfyJsonString
    }
  }
})
