




















































import { defineComponent } from '@vue/composition-api'
import { getPhoto } from '../helpers'
import UnSection from './UnSection.vue'
import Stories from './Stories.vue'
import DialogFooter from './DialogFooter.vue'
import CauseDialogSection from './CauseDialogSection.vue'

export default defineComponent({
  name: 'CauseDialog',
  props: {
    cause: {
      type: Object,
      required: true
    },
    display: {
      type: Boolean,
      required: true
    }
  },
  components: {
    UnSection,
    Stories,
    DialogFooter,
    CauseDialogSection
  },
  setup(props, { emit }) {
    const close = () => {
      emit('resetDisplay', false)
    }

    return {
      close,
      getPhoto
    }
  }
})
