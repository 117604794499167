


























import { defineComponent, ref } from '@vue/composition-api'
import { getPhoto } from '../helpers'
import CauseDialog from './CauseDialog.vue'

export default defineComponent({
  name: 'CausesTile',
  props: {
    cause: {
      type: Object,
      required: true
    }
  },
  components: {
    CauseDialog
  },
  setup() {
    const showDialog = ref(false)

    const resetDisplay = () => {
      showDialog.value = false
    }

    const onClick = () => {
      showDialog.value = true
    }

    // todo: more datalayer figuring out
    // setDataLayer() {
    //   dataLayer.push({
    //     event: 'exploreMyImpact',
    //     ecommerce: {
    //       checkout: {
    //         actionField: { step: 2, option: this.cause.title }
    //       }
    //     }
    //   })
    // }

    return {
      showDialog,
      // methods
      getPhoto,
      resetDisplay,
      onClick
    }
  }
})
