

















































import { defineComponent } from '@vue/composition-api'
import { htmlfyJsonString } from '../helpers'

export default defineComponent({
  name: 'UnSection',
  props: {
    cause: {
      type: Object,
      required: true
    }
  },
  setup() {
    const getUNPhotos = (filename: string) => {
      return require('../assets/images/unImages/' + filename)
    }

    return {
      getUNPhotos,
      htmlfyJsonString
    }
  }
})
