





























import { defineComponent } from '@vue/composition-api'
import causesData from '../data/causes.json'
import CausesTile from '../components/CausesTile.vue'
import Header from '../components/Header.vue'

export default defineComponent({
  name: 'Causes',
  components: {
    CausesTile,
    Header
  },
  setup() {
    return {
      headerTitle: 'Community Issues',
      causes: causesData,
      step: 1
    }
  }
})
